//  加载外部JS
import { Toast } from "vant";

import {
    getClientConfig,
    getAppSecretConfig,
    getPaypalApiUrl,
    getCreateOrderUrl,
    getOrderValidationUrl
} from './paypalcardCommon'
import { sync_order } from "../api/stripePay";

//  Paypal支付正式配置
const CLIENT_ID = getClientConfig(0);
const APP_SECRET = getAppSecretConfig(0);
const base = getPaypalApiUrl(0);

//  获取get参数
export function getParams(par) {
    //获取当前URL
    var local_url = document.location.href;
    //获取要取得的get参数位置
    var get = local_url.indexOf(par + "=");
    if (get == -1) {
        return false;
    }
    //截取字符串
    var get_par = local_url.slice(par.length + get + 1);
    //判断截取后的字符串是否还有其他get参数
    var nextPar = get_par.indexOf("&");
    if (nextPar != -1) {
        get_par = get_par.slice(0, nextPar);
    }
    return get_par;
}

//  组装createOrder函数中的option所需函数
export function queryParse(query) {
    let queryText = "";
    for (let key in query) {
        queryText += `${key}=${query[key]}&`;
    }
    return queryText.slice(0, -1);
}

//  订单创建
export function createOrder(data, actions) {
    console.log('进入创建订单接口')
    console.log(actions)
    console.log(data)
    //  组装参数
    const body = {
        user_id: getParams('user_id'),
        number: getParams('number'),
        pay_type: 14,
        url: getParams('url')
    };
    const url = getCreateOrderUrl();
    const options = {
        method: 'POST',
        headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
        body: queryParse(body)
    }
    return fetch(url, options).then(function (res) {
        return res.json();
    }).then(function (orderData) {
        console.log('创建订单接口返回信息：' + JSON.stringify(orderData))
        if (orderData.code == 0) {
            return orderData.data.id;
        } else {
            Toast.fail("非法请求");
            this.$router.push({ path: "/" + getParams('url') });
        }
    }).catch((res) => {
        console.log(res.message)
    });
}

//  订单验证(内部Paypal订单验证Api接口)
export async function companyOnApproveSelf(data, actions) {
    console.log('进入订单验证接口')
    console.log(actions)
    console.log(data)
    //  组装参数
    const body = {};
    const url = getOrderValidationUrl() + '?url_type=' + getParams('url') + '&token=' + data.orderID;
    const options = {
        method: 'POST',
        headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
        body: queryParse(body)
    }
    return fetch(url, options).then(function (res) {
        return res.json();
    }).then(function (orderData) {
        console.log('订单验证接口返回信息：' + JSON.stringify(orderData))
        this.$router.push({ path: "/" + getParams('url') });
    }).catch((res) => {
        console.log(res.message)
    });
}

//  订单验证(主)
export async function onApprove(data, actions) {
    console.log(data)
    console.log('actions：' + actions)
    console.log('orderID：' + data.orderID)
    try {
        console.log(111);
        return capturePayment(data.orderID);
    } catch (err) {
        alert(err.message);
    }
}

// 订单验证(被)
export async function capturePayment(orderId) {
    //  打开支付弹窗
    Toast.loading({
        message: "正在支付中，请稍等...",
        forbidClick: true
    });
    const accessToken = await generateAccessToken();
    console.log('orderId：' + orderId)
    console.log('accessToken：' + accessToken)
    const url = `${base}/v2/checkout/orders/` + orderId + `/capture`;
    return fetch(url, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(function (res) {
        return res.json();
    }).then((orderData) => {
        //  关闭弹窗
        Toast.clear(true)
        //  判断订单验证是否有误（是否成功扣款）
        if (orderData.purchase_units != undefined && orderData.purchase_units[0].payments.captures[0] != undefined && orderData.purchase_units[0].payments.captures[0].status === 'COMPLETED') {
            // 订单验证成功区间
            console.log('返回信息')
            console.log(orderData);
            var transaction = orderData.purchase_units[0].payments.captures[0];
            //  自己的业务
            console.log('Transaction ' + transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');
            if (getParams('return_url') != undefined) {
                // alert('支付成功，点击右上方关闭按钮');
                window.location.href = 'https://wx.playhy.com/#/payAppSuccess'
            } else {
                window.location.href = 'https://wx.playhy.com/#/stp_notice_page?url_type=' + getParams('url')
            }
            sync_order({
                orderData: orderData
            })
        } else {
            //  订单验证失败区间
            var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                alert('Order validation failed：' + errorDetail.issue) // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                // return alert('订单验证失败：' + errorDetail.issue) // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
            }
            if (errorDetail) {
                var msg = 'Sorry, your transaction could not be processed.';
                if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                // return alert(msg); // Show a failure message (try to avoid alerts in production environments)
                alert(msg); // Show a failure message (try to avoid alerts in production environments)
            }
            window.location.href = 'https://wx.playhy.com/#/' + getParams('url')
        }
    });
}

// 获取access token
export async function generateAccessToken() {
    const auth = Buffer.from(CLIENT_ID + ":" + APP_SECRET).toString("base64");
    const response = await fetch(`${base}/v1/oauth2/token`, {
        method: "post",
        body: "grant_type=client_credentials",
        headers: {
            Authorization: `Basic ${auth}`,
        },
    });
    const jsonData = await handleResponse(response);
    return jsonData.access_token;
}

//  公共响应类
async function handleResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return response.json();
    }
    const errorMessage = await response.text();
    throw new Error(errorMessage);
}
