<template>
    <div class="wrap">
        <div>
            <div class="paypalInfo">
                <img src="https://wx.playhy.com/card-mark.png" alt="">
                <p v-show="paypalButton">{{locale == 'us' ? 'Click the black payment button below to proceed with the payment operation':'点击下方黑色支付按钮，进行支付操作'}}</p>
            </div>
            <div  v-show="paypalButton" id="paypal-button-container" @click="isDisabled" />
        </div>

        <div v-if="!paypalButton" class="info">Billing Address</div>
        <!-- <div class="info">请务必真实信息，否则影响支付</div> -->
        <van-form v-if="!paypalButton" @submit="onSubmit">
            <van-field @blur="handleFirstNameBlur" v-model="given_name" name="given_name" :placeholder="locale == 'zh' ? 'First name(姓)':'First name'" 
            :rules="[{ required: true,message: locale == 'zh' ? '请输入英文姓':'Enter a valid First name'}]" />

            <van-field @blur="handleLastNameBlur" v-model="surname" name="surname" :placeholder="locale == 'zh' ?'Last name(名)':'Last name'" 
            :rules="[{ required: true ,message: locale == 'zh' ? '请输入英文名':'Enter a valid Last name'}]" />

            <van-field v-model="email_address" name="email_address" maxlength="30" :placeholder="locale == 'zh' ?'Email(邮箱)':'Email'"
                :rules="[{ required: true }, { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: locale == 'zh' ? '请输入正确邮箱':'Enter a valid email address'}]" />
            <!-- <van-field v-model="phone_number" name="phone_number" placeholder="Phone number"
                :rules="[{ required: true }]" /> -->

            <van-field readonly clickable name="country_code" :value="country_code_text" :placeholder="locale == 'zh' ?'country(国家)':'country'"
                @click="showPicker = true" :rules="[{ required: true }]" />
            <van-popup v-model="showPicker" position="bottom">
                <van-picker show-toolbar :columns="countryCode" @confirm="onConfirm" value-key="english_name"
                    @cancel="showPicker = false" />
            </van-popup>

            <van-field v-if="country_code == 'US'" readonly clickable name="admin_area_2" :value="admin_area_2_test"
                placeholder="state" @click="showPicker2 = true" :rules="[{ required: true }]" />
            <van-popup v-model="showPicker2" position="bottom">
                <van-picker show-toolbar :columns="USCode" @confirm="onConfirmUs" value-key="name"
                    @cancel="showPicker2 = false" />
            </van-popup>

            <van-field v-model="admin_area_1" name="admin_area_1" :placeholder="locale == 'zh' ?'City(城市)':'City'" :rules="[{ required: true }]" />

            <van-field v-model="address_line_1" name="address_line_1" :placeholder="locale == 'zh' ?'Street address(详细地址)':'Street address'"
                :rules="[{ required: true }]" />

            <van-field v-model="address_line_2" name="address_line_2" :placeholder="locale == 'zh' ?'Apt., ste., bldg.(寓所，住房；公寓楼)':'Apt., ste., bldg.'"
                :rules="[{ required: true }]" />
            <van-field v-model="postal_code" name="postal_code" :placeholder="locale == 'zh' ?'ZIP code(邮编)':'ZIP code'" :rules="[{ required: true }]" />
            <div class="submitBtn">
                <van-button round block type="info" native-type="submit">Save</van-button>
            </div>
        </van-form>
    </div>
</template>
  
<script>
import { countryCode, USCode } from "@/utils/countryJson";
import {createOrder, onApprove} from "@/utils/paycard";
import {
    saveUserinfo
} from "@/api/stripePay";
export default {
    data() {
        return {
            showPicker: false,
            showPicker2: false,
            countryCode: countryCode,
            USCode: USCode,
            orderNum: '',
            user_id: '',
            payTypeId: 2,
            url: '',
            return_url: '',

            given_name: '',
            surname: '',
            phone_number: '',
            address_line_1: "",
            address_line_2: "",
            admin_area_1: "",
            admin_area_2_test: '',
            admin_area_2: "",
            postal_code: "",
            country_code: "",
            country_code_text: '',
            email_address: "",


            //  未加载完成禁止点击
            isDisabled: false,
            paypalButton:false,
            locale:'zh'
        };
    },
    mounted: function () {
        // this.loadScript(getScriptBaseUrlUrl() + '/sdk/js?client-id=' + getClientConfig(0) + '&locale=en_US&components=buttons,funding-eligibility', () => {
        this.loadScript('https://www.paypal.com/sdk/js?client-id=ATm6pMZ8w9Z602T2upM3_hlmKmhqcUqlDSsU5NhDpBAEIeixluIEr9C-eNG2q2uz2yzvkPwnx0qSNmRA&locale=en_US&components=buttons,funding-eligibility', () => {
            //  更改按钮后的js
            var FUNDING_SOURCES = [
                // eslint-disable-next-line no-undef
                paypal.FUNDING.CARD,
            ]
            FUNDING_SOURCES.forEach(function (fundingSource) {
                // eslint-disable-next-line no-undef
                var button = paypal.Buttons({
                    fundingSource: fundingSource,
                    // 创建订单
                    createOrder: function (data, actions) {
                        return createOrder(data, actions)
                    },
                    //  订单验证
                    onApprove: function (data, actions) {
                        return onApprove(data, actions)
                    },
                    // 按钮第一次呈现时调用
                    onInit: () => {
                        // const text = document.querySelector(`.paypal-buttons`).innerHTML;
                        // eslint-disable-next-line no-undef
                        // if (text === undefined || text === null || text === ''){
                        //   console.log('未检测到paypal按钮，即将再次刷新')
                        //   // location.reload()
                        // }
                        console.log("初始化进入paypal银行卡界面");
                    },
                    // 点击付款按钮时调用 通常用于表单验证
                    onClick: (data) => {
                        console.log(data)
                        console.log("点击弹出银行卡输入界面！")
                        // const card_number = document.getElementById('#credit-card-number');
                        // card_number.textContent(123);
                    },
                    //  弹出形式弹出银行卡输入界面-返回顾客在paypal上选择的地址，具体用法参考 https://developer.paypal.com/docs/checkout/integration-features/shipping-callback/
                    // onShippingChange: (data, actions)=>{
                    //   console.log("onShippingChange", data, actions);
                    // },
                    onCancel: (data) => {
                        console.log(data)
                        console.log("取消操作！")
                    },
                    onError: (data) => {
                        // 参考资料：https://developer.paypal.com/docs/api/orders/v2/#orders_patch
                        console.log(data);
                        console.log("操作失败！")
                    }
                })
                if (button.isEligible()) {
                    // eslint-disable-next-line no-undef
                    button.render('#paypal-button-container')
                }
            })
        });
        this.$nextTick(() => {
            console.log('加载完成')
            this.isDisabled = true;
        })
    },
    created() {
        this.locale = this.$i18n.locale;
        console.log(this.$i18n.locale)
        this.orderNum = this.$route.query.number;
        this.user_id = this.$route.query.user_id;
        this.payTypeId = this.$route.query.pay_type;
        this.url = this.$route.query.url;
        this.return_url = this.$route.query.return_url;
    },
    methods: {
        //  js加载
        loadScript(url, callback) {
            const el = document.querySelector(`script[src="${url}"]`);
            if (!el) {
                const script = document.createElement('script');
                script.setAttribute('src', url);
                script.onload = callback;
                document.head.insertBefore(script, document.head.firstElementChild);
            }
        },
        handleFirstNameBlur(e){
            let val = e.target._value;
            console.log(val)
            const reg = /^[^\u4e00-\u9fa5]+$/
            if(!reg.test(val)){
               this.given_name = '';
            }
        },  
        handleLastNameBlur(e){
            let val = e.target._value;
            const reg = /^[^\u4e00-\u9fa5]+$/
            if(!reg.test(val)){
               this.surname = '';
            }
        },  
        onConfirm(value) {
            if (value.country_code != "US") {
                this.admin_area_2 = value.english_name;
            }
            this.country_code = value.country_code;
            this.country_code_text = value.english_name;
            this.showPicker = false;
        },
        onConfirmUs(value) {
            console.log(value)
            this.admin_area_2 = value.code;
            this.admin_area_2_test = value.name;
            this.showPicker2 = false;
        },
        onSubmit(values) {
            values.country_code = this.country_code;
            values.admin_area_2 = this.admin_area_2;
            values.user_id = this.user_id;
            console.log('submit', values);
            saveUserinfo(values).then((res) => {
                console.log(res)
                this.paypalButton = true;
                // this.$router.push({
                //     path: "/paypalcard",
                //     query: {
                //         number: this.orderNum,
                //         user_id: this.user_id,
                //         pay_type: this.payTypeId,
                //         url: this.url,
                //         return_url: this.return_url
                //     }
                // });
            }).catch(() => {
                this.$toast.fail('保存用户信息失败')
            });
        },
    }
}
</script>
  
<style scoped>
#paypal-button-container {
  display: flex;
  font-size: 16px;
}
.wrap {
    max-width: 480px;
    margin: 0 auto;
}

.wrap .info {
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    margin: 16px auto;
    /* color: red; */
}

.paypalInfo{
    font-size: 14px;
    text-align: center;
}

.paypalInfo img{
    width: 100%;
}

.wrap .submitBtn {
    width: 90%;
    margin: 16px auto;
}

.van-cell {
    border: 2px solid rgb(183, 188, 191);
    box-sizing: border-box;
    font-size: 16px;
    min-height: 48px;
    outline: 0px;
    /* padding: 16px 15px 0px; */
    width: 90%;
    appearance: none;
    border-radius: 6px;
    margin: 12px auto 0;
}

.van-cell input {
    height: 48px;
}
</style>
  