//  paypal测试环境配置
const sandboxClientId = 'AbQHNoRtPGf_yjV-BU8oMMj6gBCmaGh5sUgX10z-A7DHCi5VLIVGicf68QPbq-Zh-RvxZ4L7t9DVJyUv'
const sandboxAppSecret = ''

//  paypal正式环境配置
const productionClientId = 'ATm6pMZ8w9Z602T2upM3_hlmKmhqcUqlDSsU5NhDpBAEIeixluIEr9C-eNG2q2uz2yzvkPwnx0qSNmRA'
const productionAppSecret = ''

//  PaypalAPI正式地址
const scriptBaseUrl = 'https://www.paypal.com';
const sandboxApiBaseUrl = 'https://api.sandbox.paypal.com';
const productionApiBaseUrl = 'https://api.paypal.com';

//  公司paypal创建订单|验证订单接口地址
const createOrderUrl = 'https://wx.playhy.com/third/paypal_bank/paypal/create_order'  //正式服
// const createOrderUrl = 'https://beta-api.hzarcticwolf.com/third/sandbox_paypal_bank/paypal/create_order'; //测试服

const orderValidationUrl = 'https://wx.playhy.com/third/paypal_bank/paypal/capture_order'

//  获取paypal银行卡CLIENT_ID基本配置
export function getClientConfig($isSendBox) {
    switch ($isSendBox) {
        case 0:
            return productionClientId;
        case 1:
            return sandboxClientId;
    }
}

//  获取paypal银行卡APP_SECRET基本配置
export function getAppSecretConfig($isSendBox) {
    switch ($isSendBox) {
        case 0:
            return productionAppSecret;
        case 1:
            return sandboxAppSecret;
    }
}

//  获取paypal银行卡正式环境Api地址
export function getPaypalApiUrl($isSendBox) {
    switch ($isSendBox) {
        case 0:
            return productionApiBaseUrl;
        case 1:
            return sandboxApiBaseUrl;
    }
}

//  获取paypal银行卡正式环境Api地址
export function getScriptBaseUrlUrl() {
    return scriptBaseUrl;
}

//  获取公司paypal创建订单接口地址
export function getCreateOrderUrl() {
    return createOrderUrl
}

//  获取公司paypal订单验证接口地址
export function getOrderValidationUrl() {
    return orderValidationUrl
}


